'use client';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { InfoIcon } from '../icons/icons';

interface Props {
  tooltipTitle?: string;
  isDesktop: boolean;
  isActiveInfoTooltip: boolean;
  TooltipText: string;
  TooltipLeftToRight: number;
  TooltipTopToDown: number;
}

const NativeCardTooltip: React.FC<Props> = ({
  isActiveInfoTooltip,
  TooltipText,
  TooltipLeftToRight,
  TooltipTopToDown,
  isDesktop,
}) => {
  const [tooltipVis, setTooltipVis] = useState(false);

  const tooltipRef = useRef<any>(null);

  useEffect(() => {
    const handler = (e: { target: any }) => {
      if (!tooltipRef?.current?.contains(e.target)) {
        !isDesktop ? setTooltipVis(false) : null;
      }
    };

    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  const tooltipTitleCls = classNames('text-xs text-black900 leading-4 p-1 font-semibold');
  const tooltipStructureCls = classNames(
    'absolute z-50 w-[75vw] md:w-[314px] rounded-lg bg-white px-4 py-3 shadow-tooltip'
  );
  const tooltipStructureTrasnforms = classNames('transform md:-translate-x-1/2 -translate-y-[calc(100%+1.7rem)]');
  const tooltipStructureBeforeTrasnforms = classNames(
    'before:absolute before:content-[""] before:bg-white before:h-[14px] before:w-[14px] before:-bottom-[5px] md:before:left-[calc(50%+8px)]',
    'before:transform before:-translate-x-1/2 before:rotate-45'
  );

  const tooltiptextPos = classNames(
    TooltipLeftToRight < 25
      ? '-translate-x-[5%]'
      : 25 >= TooltipLeftToRight && TooltipLeftToRight < 50
        ? '-translate-x-[25%]'
        : 50 >= TooltipLeftToRight && TooltipLeftToRight <= 75
          ? '-translate-x-1/2'
          : '-translate-x-[85%]',
    TooltipLeftToRight < 25
      ? 'before:left-[calc(5%+5px)]'
      : 25 >= TooltipLeftToRight && TooltipLeftToRight < 50
        ? 'before:left-[calc(25%+5px)]'
        : 50 >= TooltipLeftToRight && TooltipLeftToRight <= 75
          ? 'before:left-[calc(50%+5px)]'
          : 'before:left-[calc(85%+5px)]'
  );

  return (
    <>
      {isActiveInfoTooltip && (
        <div
          ref={tooltipRef}
          className={classNames('absolute cursor-pointer')}
          style={{
            top: `${TooltipTopToDown}%`,
            left: `${TooltipLeftToRight}%`,
          }}
          onMouseLeave={() => {
            isDesktop ? setTooltipVis(false) : null;
          }}
          onTouchStart={() => {
            !isDesktop ? setTooltipVis(true) : null;
          }}
        >
          <div className={classNames({ visible: tooltipVis }, 'relative')}>
            <span
              onMouseEnter={() => {
                isDesktop ? setTooltipVis(true) : null;
              }}
            >
              <InfoIcon width={18} height={18} className="inline cursor-pointer" />
            </span>
            <div
              className={classNames(
                tooltipStructureCls,
                tooltipVis ? 'visible' : 'invisible',
                tooltipStructureTrasnforms,
                tooltipStructureBeforeTrasnforms,
                tooltiptextPos
              )}
            >
              <div className={tooltipTitleCls}>{TooltipText}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NativeCardTooltip;
